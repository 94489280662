.hero {
    .container {
        position: relative;
        .row {
            align-items: center;
        }
    }

    &-image {
        width: 100%;
        height: 100%;

        padding: 17.8rem 0;

        display: flex;
        justify-content: flex-end;

        position: relative;

        > img {
            position: relative;
            z-index: 2;
        }

        @media (max-width: 991px) {
            justify-content: center;
            padding: 17.8rem 0 5rem 0;
        }

        @media (max-width: 575px) {
            position: static;
            padding: 5rem 0 0 0;
            > img {
                transform: scale(.7)
            }

            .hero-absolute-image {
                img {
                    transform-origin: top center;
                    transform: scale(.7)
                }
            }
        }
    }

    &-content {
        position: relative;
        z-index: 2;

        padding-top: 17.8rem;

        p {
            margin: 3rem 0 4.8rem 0;
        }

        @media (max-width: 991px) {
            padding: 0 0 10rem 0;
            text-align: center;

            p {
                margin: 3rem 0 3rem 0;
            }
        }

        @media (max-width: 575px) {
            padding: 0 0 10rem 0;
            margin-top: -5rem;
        }
    }

    .feature-section-image {

    }

    &-1 {
        .hero-absolute-image {
            position: absolute;
            top: -.1rem;
            right: -41%;

            z-index: 1;



            @media (max-width: 1199px) {
                right: -51%;
            }

            @media (max-width: 991px) {
                right: -19%;
            }

            @media (max-width: 767px) {
                right: -42%;
            }

            @media (max-width: 575px) {
                right: initial;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}