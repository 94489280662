.about-section {

    &-wrapper {
        padding-bottom: 15rem;
    }

    &-content {
        > *:nth-child(n+2) {
                margin-top: 3.5rem;
        }

        .paragraph-big {
            margin-top: 2rem;
        }
    }

    .second-half-heading {
        margin: 4.3rem 0 5.7rem 0;
    }

    @media (max-width: 991px) {
        &-wrapper {
            padding-bottom: 5rem;
        }

        &-content {
            margin-top: 5rem;
        }

        .second-half-heading {
            margin: 3rem 0 3rem 0;
        }

        .about-row {
            div[class*="col"] {
                &:nth-child(n+2) {
                    margin-top: 3rem;
                }
            }
            .col-lg-6 {
                margin-top: 3rem; // Adjust the value as needed
              }

              .col-lg-6:first-child {
                margin-top: 0; // Removes top margin from the first element if you want
              }
        }
    }
}